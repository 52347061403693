import React, { useRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "../../styles/OurTeam.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as data from "../../../data.js";
import GoToTop from "../../GoToTop";
import { FormattedMessage } from "react-intl";

export default function OurTeam({ currentLanguage }) {
  const dataModules = require.context("../../../data", false, /\.js$/);
  console.log(dataModules, "dataModules");
  const languageData = {};

  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });
  const [data, setData] = useState(languageData[currentLanguage]);
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  const box1 = useRef(null);
  const box2 = useRef(null);
  const box3 = useRef(null);
  const box4 = useRef(null);
  const box5 = useRef(null);
  const box6 = useRef(null);
  const box7 = useRef(null);
  const box8 = useRef(null);
  const box9 = useRef(null);
  const box10 = useRef(null);
  const box11 = useRef(null);
  const box12 = useRef(null);
  const box13 = useRef(null);
  const box14 = useRef(null);

  useEffect(() => {
    const boxes1 = [box1.current, box2.current, box3.current];

    const boxes2 = [box4.current, box5.current, box6.current];

    const boxes3 = [box7.current, box8.current, box9.current];

    const boxes4 = [
      box10.current,
      box11.current,
      box12.current,
      box13.current,
      box14.current,
    ];

    boxes1.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        stagger: 0.2,
        y: 0,
        scale: 1,
        duration: 1.5,

        delay: index * 0.2,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes2.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,

        delay: index * 0.3,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes3.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,

        delay: index * 0.3,
        duration: 1.5,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes4.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.3,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });
  }, []);

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  const addToSecondRef = (el) => {
    if (el && !revealsSecondRef.current.includes(el)) {
      revealsSecondRef.current.push(el);
    }
  };

  return (
    <div className={styles.ourteam_container}>
      <GoToTop />
      <div className={styles.aboutUs}>
        <div className={styles["aboutus-container"]} ref={detailRef}>
          <div className={styles["aboutUs-title"]}>
            <h1 className={styles.aboutheading1}>DATA TEMPLATE</h1>
            <h1 className={styles.aboutheading2}>
              <FormattedMessage id="heading_our_team" />
            </h1>
          </div>
          <div className={styles["aboutUs-content"]}>
            <p className={styles["aboutUs-contentp"]}>
              <FormattedMessage id="paragraph_our_team" />
            </p>{" "}
            <br />
          </div>
        </div>
        <div className={styles["aboutUs-img"]}>
          <Grid
            id="about-part"
            className={styles["about-part"]}
            container
            spacing={2}
          >
            {data.ourteam.map((item, index) => {
              return (
                <Grid
                  className={styles.aboutimages}
                  key={index}
                  ref={addToFirstRef}
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <div className={styles["about-img-space"]} id={item.id}>
                    <div className={styles["img-hover-zoom"]}>
                      <img
                        className={styles["about-img"]}
                        id="imghover"
                        src={process.env.PUBLIC_URL + item.img}
                        alt="About"
                        loading="lazy"
                      />
                    </div>
                    <p className={styles.abouttitle}>{item.title}</p>
                    <h4 className={styles.aboutsubtitle}>{item.subTitle}</h4>
                    <p className={styles.aboutsubtitle2}>{item.subTitle2}</p>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <div className={styles["aboutUs2-img"]}>
          <Grid
            id="about-part"
            className={`${styles["about-part"]} ${styles["about-part2"]}`}
            container
            spacing={2}
          >
            {/* <Grid
              ref={box1}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}>
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id='imghover'
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/ourteam/pradeep.svg"
                  }
                  alt='About'
                  loading='lazy'
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  Senior software engineer Opensource
                </p>
                <h4 className={styles.aboutsubtitle2}>Pradeep Nayak</h4>
                <p className={styles.aboutsubtitle2}>
                  Data Template is a company that inspires me. I like the
                  mindset and culture, and being part of a team that gives its
                  best to bring forward new technology. I’m investing my time,
                  my knowledge and experience in a company that is investing in
                  me.
                </p>
              </div>
            </Grid> */}
            <Grid
              ref={box2}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/ourteam/hrteamoprn.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="hr_operation_ourteam" />
                </p>
                <h4 className={styles.aboutsubtitle2}>
                  Yashmitha Mutta & Sreeram S
                </h4>
                <p className={styles.aboutsubtitle2}>
                  <FormattedMessage id="hr_operation_sub_ourteam" />
                </p>
              </div>
            </Grid>
            <Grid
              ref={box3}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/ourteam/IT_DT.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="it_title_ourteam" />
                </p>
                <h4 className={styles.aboutsubtitle2}>
                  Lijin Vijayan & Shivaprasad M Babu
                </h4>
                <p className={styles.aboutsubtitle2}>
                  <FormattedMessage id="it_sub_ourteam" />
                </p>
              </div>
            </Grid>
            <Grid
              ref={box4}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/ourteam/administrations.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="administration_ourteam" />
                </p>
                <h4 className={styles.aboutsubtitle2}>
                  Kazi, Amitab, Riwas & Dewan
                </h4>
                <p className={styles.aboutsubtitle2}>
                  <FormattedMessage id="admi_sub_ourteam" />
                </p>
              </div>
            </Grid>
            <Grid
              ref={box5}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/ourteam/jagadeesh.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="ass_manager_ourteam" />
                </p>
                <h4 className={styles.aboutsubtitle2}>
                  Jagdeesh Babu Bolikondu
                </h4>
                <p className={styles.aboutsubtitle2}>
                  <FormattedMessage id="ass_manager_sub_ourteam" />
                </p>
              </div>
            </Grid>
            <Grid
              ref={box6}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/ourteam/prajna.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="prajna_ourteam" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Prajna Tilak</h4>
                <p className={styles.aboutsubtitle2}>
                  <FormattedMessage id="prajna_sub_ourteam" />
                </p>
              </div>
            </Grid>
            <Grid
              ref={box7}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/ourteam/ashikupd.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="finance_ourteam" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Ashik Arakkal Sabu</h4>
                <p className={styles.aboutsubtitle2}>
                  <FormattedMessage id="finance_sub_ourteam" />
                </p>
              </div>
            </Grid>
            <Grid
              ref={box8}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/ourteam/sahnaz.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="sahnaz_ourteam" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Sahnaz Ahmed</h4>
                <p className={styles.aboutsubtitle2}>
                  <FormattedMessage id="sahnaz_sub_ourteam" />
                </p>
              </div>
            </Grid>

            <Grid
              ref={box9}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/ourteam/qcteam.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="quality_control_ourteam" />
                </p>
                <h4 className={styles.aboutsubtitle2}>
                  Yogamiya Priya & Megha
                </h4>
                <p className={styles.aboutsubtitle2}>
                  <FormattedMessage id="quality_control_sub_ourteam" />
                </p>
              </div>
            </Grid>
            {/* <Grid
              ref={box9}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}>
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id='imghover'
                  src={
                    process.env.PUBLIC_URL + "/assets/images/ourteam/updjaspreet.svg"
                  }
                  alt='About'
                  loading='lazy'
                />
              </div>

              <div>
                <p className={styles.abouttitle}>Senior software engineer Dot Net</p>
                <h4 className={styles.aboutsubtitle2}>Jaspreet</h4>
                <p className={styles.aboutsubtitle2}>
                I have recently joined Data Template and have never faced such
                  beautiful work environment anywhere else in Bangalore. Very
                  good ambience and work culture. In Data Template your hard
                  works really gets recognised and appreciated.
                </p>
              </div>
            </Grid> */}
            {/* <Grid
              ref={box9}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}>
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id='imghover'
                  src={
                    process.env.PUBLIC_URL + "/assets/images/ourteam/sajinm.svg"
                  }
                  alt='About'
                  loading='lazy'
                />
              </div>

              <div>
                <p className={styles.abouttitle}>Technical Lead Dot Net</p>
                <h4 className={styles.aboutsubtitle2}>Sajin M</h4>
                <p className={styles.aboutsubtitle2}>
                  I am very gratified with Data Template’s strong ethical
                  values, where they offer with same level of aids such as their
                  executives, we can easily narrate our thoughts, and they value
                  multiculturalism. I am very happy that I became part of Data Template,
                  as I’ve apprehended my potentials. I would say this is my
                  career turning point, Data Template provided me a good panorama to
                  cultivate myself in terms of skills and as a specialized
                  technologist.
                </p>
              </div>
            </Grid> */}
            {/* <Grid
              ref={box10}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/ourteam/kriti.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  Senior Software Engineer Opensource
                </p>
                <h4 className={styles.aboutsubtitle2}>Kriti Praveen</h4>
                <p className={styles.aboutsubtitle2}>
                  I have recently joined Data Template and have never faced such
                  beautiful work environment anywhere else in Mangalore. Very
                  good ambience and work culture. In Data Template your hard
                  works really gets recognised and appreciated.
                </p>
              </div>
            </Grid> */}
            {/* <Grid
              ref={box11}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}>
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id='imghover'
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/ourteam/aravindanew.svg"
                  }
                  alt='About'
                  loading='lazy'
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  Senior software engineer Opensource
                </p>
                <h4 className={styles.aboutsubtitle2}>Aravinda Bolubailu</h4>
                <p className={styles.aboutsubtitle2}>
                  Being in Data Template got a lot of opportunities to work with
                  various new technologies and exciting domains which are
                  currently running across. Also having a funful and great team
                  in Data Template helps to create good products.
                </p>
              </div>
            </Grid> */}
            <Grid
              ref={box13}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/ourteam/rituraj.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="tech_lead_ourteam" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Rituraj Singh</h4>
                <p className={styles.aboutsubtitle2}>
                  <FormattedMessage id="tech_lead_sub_ourteam" />
                </p>
              </div>
            </Grid>
            <Grid
              ref={box14}
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={styles["home-content"]}
            >
              <div className={styles["img-hover-zoom"]}>
                <img
                  className={styles["img-width"]}
                  id="imghover"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/ourteam/shika.svg"
                  }
                  alt="About"
                  loading="lazy"
                />
              </div>

              <div>
                <p className={styles.abouttitle}>
                  <FormattedMessage id="hr_rec_ourteam" />
                </p>
                <h4 className={styles.aboutsubtitle2}>Shika Alva</h4>
                <p className={styles.aboutsubtitle2}>
                  <FormattedMessage id="hr_rec_sub_ourteam" />
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
