import { Grid } from "@mui/material";
import gsap from "gsap";
import { TweenMax } from "gsap/gsap-core";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import GoToTop from "../../GoToTop";
import styles from "../../styles/Enterprise.module.css";
import { FormattedMessage } from "react-intl";

export default function Enterprise() {
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const detailRef1 = useRef(null);
  

  useEffect(()=>{
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    const element1 = detailRef1.current;
    gsap.fromTo(
      element1,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
  },[])

 

  return (
    <div className={styles.enterprise_container}>
      <GoToTop />
      <Grid container className={styles.ElementEnterpriseFirst} ref={detailRef}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "28px" }}>
          <div className={styles["enterprise-block"]} >
        
            <div>
              <h3 className={styles["enterprise-h3"]}>
              <FormattedMessage id="enterprise_bi_h"/>
              </h3>
            </div>
            
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}    >
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/enterprise/Drilldown1.svg"
            }
            className={styles["enterprise-img"]}
            alt='enterprise'
          />
        </Grid>
      </Grid>

      <div className={styles.enterpriseMain} ref={detailRef1}>
        <Grid container className={styles.ElementEnterprises}>
          <Grid item>
    
          </Grid>
          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>
              <FormattedMessage id="enterprise_bi_h1"/>
              </h3>

              <p className={styles.PSubHeading}>
                <FormattedMessage id="enterprise_bi_p1"/>
              </p>

              <p className={styles.PSubHeading}>
                <FormattedMessage id="enterprise_bi_p2"/>
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>
                <FormattedMessage id="enterprise_bi_appli_h"/>
              </h3>

              <p className={styles.PSubHeading}>
                <FormattedMessage id="enterprise_bi_appli_p"/>
              </p>
          
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}><FormattedMessage id="enterprise_bi_customer_h"/></h3>

              <p className={styles.PSubHeading}>
                <FormattedMessage id="enterprise_bi_customer_p"/>
              </p>
             
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/enterprise/Drilldown2.svg"
              }
              className={styles["enterprise-img"]}
              alt='enterprise'
            />
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <p className={styles.PSubHeading}>
                <FormattedMessage id="enterprise_bi_customer_p1"/>
              </p>
             
            </div>
          </Grid>
        </Grid>
        
      </div>
    </div>
  );
}
