import React, { useRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "../../styles/Blog.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as data from "../../../data.js";
import GoToTop from "../../GoToTop";
import { Link } from "react-router-dom";
import Search from "../CustomSearch/Search";
import { useIntl } from 'react-intl';
export default function Blog({currentLanguage}) {
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'blog_search' });
  const dataModules = require.context("../../../data", false, /\.js$/);
  console.log(dataModules, "dataModules");
  const languageData = {};

  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });
  const [data, setData] = useState(languageData[currentLanguage]);
  gsap.registerPlugin(ScrollTrigger);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const lang = params.get("lang") !== null ? params.get("lang") : 'en';
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  const [searchBlogs, setSearchBlogs] = useState('')
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });

    revealsSecondRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  const addToSecondRef = (el) => {
    if (el && !revealsSecondRef.current.includes(el)) {
      revealsSecondRef.current.push(el);
    }
  };



  const blogSearchHAndler = (e) => {
    setSearchBlogs(e.target.value)
  }
  const filterBlogs = data?.blog_imgs.filter(item => {
    return (
      item.title.toLowerCase().includes(searchBlogs.toLowerCase()) ||
      item.subTitle.toLowerCase().includes(searchBlogs.toLowerCase()) 
    )
  })

  const blogcloseButtonHandler = () => {
    setSearchBlogs('')
  }


  return (
    <div className={styles.blog_container}>
      <GoToTop />
      <div className={styles.blog}>
        <div ref={detailRef}>
          <div className={styles["blog-title"]}>
            <h2 className={styles["blog-heading"]}>Blogs</h2>
          </div>

          <div className={styles["blog-content"]}>
            <div className={styles["input-icon my-3 ml-auto"]}>
              <Search
              
                id='search'
                type='search'
                className={styles.inpWidth}
                placeholder={placeholder}
                tabIndex='1'
                aria-label='Search'
                onChange={blogSearchHAndler}
                value={searchBlogs}
                onClick={blogcloseButtonHandler}
                closeBtn={true}
              />
              
            </div>
          </div>
        </div>

        <div className={styles["blog-img"]}>
          <Grid
            id='blog-part'
            className={styles["blog-part"]}
            container
            spacing={2}>
            {filterBlogs.map((item, index) => {
              return (
                <Grid
                  key={index}
                  className={styles["blog-imgs"]}
                  ref={addToSecondRef}
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}>
                  <div className='' >
                    <div className={styles["img-hover-zoom"]}>
                    <Link 
                    New
                    Message
                    to={{
                      pathname: `${item.id}`,
                      search: `?lang=${lang}`,
                    }}
                    
                    
                    >
                      <img
                        className={styles["blog-imghover"]}
                        id='imghover'
                        src={process.env.PUBLIC_URL + item.img}
                        alt={item.title}
                        loading='lazy'
                      />
                        </Link>
                    </div>
                    <p className={styles.blogtitle}>{item.title}</p>
                    <h4 className={styles.blogsubtitle}>{item.subTitle}</h4>
                  </div>
                </Grid>
              );
            })}
            {filterBlogs.length === 0 && <p style={{margin:'auto'}}>No Blogs Found!</p>}
          </Grid>
        </div>
      </div>
    </div>
  );
}
